<template>
  <div id="adverseEventAdditional">
    <el-dialog
      :title="adverseEventAdditionalFormTitle"
      width="1200px"
      :visible.sync="adverseEventAdditionalDialogVisible"
      :close-on-click-modal="false"
      @close="adverseEventAdditionalDialogClose"
    >
      <el-form
        ref="adverseEventAdditionalFormRef"
        :model="adverseEventAdditionalForm"
        :rules="adverseEventAdditionalFormRules"
        label-position="right"
        label-width="120px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="报告时间" prop="reportTime">
              <el-date-picker v-model="adverseEventAdditionalForm.reportTime" placeholder="请选择报告时间" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="编码" prop="code">
              <el-input v-model="adverseEventAdditionalForm.code" placeholder="请输入编码" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="首次报告时间" prop="firstReportTime">
              <el-date-picker v-model="adverseEventAdditionalForm.firstReportTime" placeholder="请选择首次报告时间" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="企业名称" prop="enterpriseName">
              <el-input v-model="adverseEventAdditionalForm.enterpriseName" placeholder="请输入企业名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="企业地址" prop="enterpriseAddress">
              <el-input v-model="adverseEventAdditionalForm.enterpriseAddress" placeholder="请输入企业地址" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="联系人" prop="contact">
              <el-input v-model="adverseEventAdditionalForm.contact" placeholder="请输入联系人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="传真" prop="fax">
              <el-input v-model="adverseEventAdditionalForm.fax" placeholder="请输入传真" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="邮编" prop="postcode">
              <el-input v-model="adverseEventAdditionalForm.postcode" placeholder="请输入邮编" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="电话" prop="telephone">
              <el-input v-model="adverseEventAdditionalForm.telephone" placeholder="请输入电话" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="事件涉及产品" prop="productInvolved">
              <el-input
                v-model="adverseEventAdditionalForm.productInvolved"
                placeholder="请输入事件涉及产品"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="事件跟踪信息" prop="trackingInfo">
              <el-input
                v-model="adverseEventAdditionalForm.trackingInfo"
                placeholder="请输入事件跟踪信息（至少包括：患者转归、调查分析及控制措施）"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="产品信息" prop="productInfo">
              <el-input
                v-model="adverseEventAdditionalForm.productInfo"
                placeholder="请输入产品信息"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="上传材料">
              <el-scrollbar style="height: 200px">
                <el-upload
                  ref="uploadRef"
                  action=""
                  :http-request="handleHttp"
                  :on-remove="handleRemove"
                  :file-list="fileList"
                  multiple
                >
                  <el-button size="small" type="primary">
                    点击上传
                  </el-button>
                </el-upload>
              </el-scrollbar>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="监测技术机构评价意见" prop="comment">
              <el-input
                v-model="adverseEventAdditionalForm.comment"
                placeholder="请输入监测技术机构评价意见"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="省级监测技术机构评价意见" prop="provinceComment">
              <el-input
                v-model="adverseEventAdditionalForm.provinceComment"
                placeholder="请输入省级监测技术机构评价意见"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="国家监测技术机构评价意见" prop="countryComment">
              <el-input
                v-model="adverseEventAdditionalForm.countryComment"
                placeholder="请输入国家监测技术机构评价意见"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="报告人" prop="reporter">
              <el-input v-model="adverseEventAdditionalForm.reporter" placeholder="请输入报告人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="省级监测技术机构接收日期" prop="provinceReceivedDate">
              <el-date-picker v-model="adverseEventAdditionalForm.provinceReceivedDate" placeholder="请选择省级监测技术机构接收日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="国家监测技术机构接收日期" prop="countryReceivedDate">
              <el-date-picker v-model="adverseEventAdditionalForm.countryReceivedDate" placeholder="请选择国家监测技术机构接收日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="生产企业" prop="manufacturer">
              <el-input v-model="adverseEventAdditionalForm.manufacturer" placeholder="请输入生产企业" clearable />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="adverseEventAdditionalDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="adverseEventAdditionalFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="企业名称">
        <el-input v-model="searchForm.enterpriseName" placeholder="请输入企业名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="adverseEventAdditionalPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column label="报告时间">
        <template slot-scope="scope">
          <span v-if="scope.row.reportTime">{{ scope.row.reportTime.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="code" label="编码" />
      <el-table-column label="首次报告时间">
        <template slot-scope="scope">
          <span v-if="scope.row.firstReportTime">{{ scope.row.firstReportTime.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="enterpriseName" label="企业名称" />
      <el-table-column prop="enterpriseAddress" label="企业地址" />
      <el-table-column prop="contact" label="联系人" />
      <el-table-column prop="fax" label="传真" />
      <el-table-column prop="postcode" label="邮编" />
      <el-table-column prop="telephone" label="电话" />
      <el-table-column prop="productInvolved" label="事件涉及产品" />
      <el-table-column prop="trackingInfo" label="事件跟踪信息" />
      <el-table-column prop="productInfo" label="产品信息" />
      <el-table-column prop="comment" label="监测技术机构评价意见" />
      <el-table-column prop="provinceComment" label="省级监测技术机构评价意见" />
      <el-table-column prop="countryComment" label="国家监测技术机构评价意见" />
      <el-table-column prop="reporter" label="报告人" />
      <el-table-column label="省级监测技术机构接收日期">
        <template slot-scope="scope">
          <span v-if="scope.row.provinceReceivedDate">{{ scope.row.provinceReceivedDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="国家监测技术机构接收日期">
        <template slot-scope="scope">
          <span v-if="scope.row.countryReceivedDate">{{ scope.row.countryReceivedDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="manufacturer" label="生产企业" />
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="adverseEventAdditionalPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addAdverseEventAdditional, deleteAdverseEventAdditional, updateAdverseEventAdditional, selectAdverseEventAdditionalInfo, selectAdverseEventAdditionalList } from '@/api/quality/adverseEventAdditional'
import { upload } from '@/api/main/file'

export default {
  data () {
    return {
      adverseEventAdditionalDialogVisible: false,
      adverseEventAdditionalFormTitle: '',
      adverseEventAdditionalForm: {
        id: '',
        reportTime: '',
        code: '',
        firstReportTime: '',
        enterpriseName: '',
        enterpriseAddress: '',
        contact: '',
        fax: '',
        postcode: '',
        telephone: '',
        productInvolved: '',
        trackingInfo: '',
        productInfo: '',
        materialName: '',
        materialPath: '',
        comment: '',
        provinceComment: '',
        countryComment: '',
        reporter: '',
        provinceReceivedDate: '',
        countryReceivedDate: '',
        manufacturer: ''
      },
      adverseEventAdditionalFormRules: {
        enterpriseName: [{ required: true, message: '企业名称不能为空', trigger: ['blur', 'change']}]
      },
      adverseEventAdditionalPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        enterpriseName: ''
      },
      fileList: []
    }
  },
  created () {
    selectAdverseEventAdditionalList(this.searchForm).then(res => {
      this.adverseEventAdditionalPage = res
    })
  },
  methods: {
    adverseEventAdditionalDialogClose () {
      this.$refs.adverseEventAdditionalFormRef.resetFields()
      this.$refs.uploadRef.clearFiles()
      this.fileList = []
    },
    adverseEventAdditionalFormSubmit () {
      if (this.adverseEventAdditionalFormTitle === '医疗器械不良事件补充报告详情') {
        this.adverseEventAdditionalDialogVisible = false
        return
      }
      this.$refs.adverseEventAdditionalFormRef.validate(async valid => {
        if (valid) {
          this.adverseEventAdditionalForm.materialName = this.fileList.map(file => file.name).join(',')
          this.adverseEventAdditionalForm.materialPath = this.fileList.map(file => file.path).join(',')
          if (this.adverseEventAdditionalFormTitle === '新增医疗器械不良事件补充报告') {
            await addAdverseEventAdditional(this.adverseEventAdditionalForm)
          } else if (this.adverseEventAdditionalFormTitle === '修改医疗器械不良事件补充报告') {
            await updateAdverseEventAdditional(this.adverseEventAdditionalForm)
          }
          this.adverseEventAdditionalPage = await selectAdverseEventAdditionalList(this.searchForm)
          this.adverseEventAdditionalDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.adverseEventAdditionalFormTitle = '新增医疗器械不良事件补充报告'
      this.adverseEventAdditionalDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteAdverseEventAdditional(row.id)
        if (this.adverseEventAdditionalPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.adverseEventAdditionalPage = await selectAdverseEventAdditionalList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.adverseEventAdditionalFormTitle = '修改医疗器械不良事件补充报告'
      this.adverseEventAdditionalDialogVisible = true
      this.selectAdverseEventAdditionalInfoById(row.id)
    },
    handleInfo (index, row) {
      this.adverseEventAdditionalFormTitle = '医疗器械不良事件补充报告详情'
      this.adverseEventAdditionalDialogVisible = true
      this.selectAdverseEventAdditionalInfoById(row.id)
    },
    selectAdverseEventAdditionalInfoById (id) {
      selectAdverseEventAdditionalInfo(id).then(res => {
        this.adverseEventAdditionalForm.id = res.id
        this.adverseEventAdditionalForm.reportTime = res.reportTime
        this.adverseEventAdditionalForm.code = res.code
        this.adverseEventAdditionalForm.firstReportTime = res.firstReportTime
        this.adverseEventAdditionalForm.enterpriseName = res.enterpriseName
        this.adverseEventAdditionalForm.enterpriseAddress = res.enterpriseAddress
        this.adverseEventAdditionalForm.contact = res.contact
        this.adverseEventAdditionalForm.fax = res.fax
        this.adverseEventAdditionalForm.postcode = res.postcode
        this.adverseEventAdditionalForm.telephone = res.telephone
        this.adverseEventAdditionalForm.productInvolved = res.productInvolved
        this.adverseEventAdditionalForm.trackingInfo = res.trackingInfo
        this.adverseEventAdditionalForm.productInfo = res.productInfo
        if (res.materialName) {
          const materialName = res.materialName.split(',')
          const materialPath = res.materialPath.split(',')
          this.fileList = []
          for (let i = 0; i < materialName.length; i++) {
            this.fileList.push({
              name: materialName[i],
              path: materialPath[i]
            })
          }
        }
        this.adverseEventAdditionalForm.comment = res.comment
        this.adverseEventAdditionalForm.provinceComment = res.provinceComment
        this.adverseEventAdditionalForm.countryComment = res.countryComment
        this.adverseEventAdditionalForm.reporter = res.reporter
        this.adverseEventAdditionalForm.provinceReceivedDate = res.provinceReceivedDate
        this.adverseEventAdditionalForm.countryReceivedDate = res.countryReceivedDate
        this.adverseEventAdditionalForm.manufacturer = res.manufacturer
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectAdverseEventAdditionalList(this.searchForm).then(res => {
        this.adverseEventAdditionalPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectAdverseEventAdditionalList(this.searchForm).then(res => {
        this.adverseEventAdditionalPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectAdverseEventAdditionalList(this.searchForm).then(res => {
        this.adverseEventAdditionalPage = res
      })
    },
    handleHttp (file) {
      const data = new FormData()
      data.append('file', file.file)
      upload(data).then(res => {
        this.fileList.push({
          name: file.file.name,
          path: res,
          uid: file.file.uid
        })
      })
    },
    handleRemove (file, fileList) {
      this.fileList = fileList
    }
  }
}
</script>

<style>
#adverseEventAdditional .el-scrollbar .el-scrollbar__wrap {
  overflow-x: hidden;
}
</style>
