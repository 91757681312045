import axios from '@/common/axios'
import qs from 'qs'

export function addAdverseEventAdditional (data) {
  return axios({
    method: 'post',
    url: '/quality/adverseEventAdditional/add',
    data: qs.stringify(data)
  })
}

export function deleteAdverseEventAdditional (id) {
  return axios({
    method: 'delete',
    url: '/quality/adverseEventAdditional/delete/' + id
  })
}

export function updateAdverseEventAdditional (data) {
  return axios({
    method: 'put',
    url: '/quality/adverseEventAdditional/update',
    data: qs.stringify(data)
  })
}

export function selectAdverseEventAdditionalInfo (id) {
  return axios({
    method: 'get',
    url: '/quality/adverseEventAdditional/info/' + id
  })
}

export function selectAdverseEventAdditionalList (query) {
  return axios({
    method: 'get',
    url: '/quality/adverseEventAdditional/list',
    params: query
  })
}
